<template>
  <app-layout>
    <template v-slot:header> Plata # {{ paymentId }} </template>
    <page-section-header>
      Suma: {{ paymentAmount }}
      <template v-slot:actions> </template>
    </page-section-header>

    <v-card>
      <v-card-header>
        <v-card-title>Distribuire plata catre vanzatori</v-card-title>
      </v-card-header>
      <loader v-if="loading" class="my-5" />
      <template v-else>
        <template v-if="totalRows">
          <payments-show-table
            :rows="rows"
            @update:status="updateVendorPayment"
          />
          <pagination
            @change:page="index($event)"
            :total="meta.total"
            :current_page="meta.current_page"
            :from="meta.from"
            :to="meta.to"
            :next_page_url="links.next"
            :prev_page_url="links.prev"
          />
        </template>
        <no-results v-else class="m-5" />
      </template>
    </v-card>
  </app-layout>
</template>

<script>
import PaymentsShowTable from "../components/PaymentsShowTable.vue";
import VCard from "../components/ui/VCard.vue";
import AppLayout from "../layout/AppLayout.vue";
import Loader from "../components/Loader.vue";
import Pagination from "../components/Pagination.vue";
import NoResults from "../components/NoResults.vue";
import { priceFormatter } from "@/helpers";
import paymentsApi from "../api/resources/paymentsApi";
import VCardHeader from "../components/ui/VCardHeader.vue";
import VCardTitle from "../components/ui/VCardTitle.vue";
import PageSectionHeader from "../components/ui/PageSectionHeader.vue";

export default {
  components: {
    PaymentsShowTable,
    AppLayout,
    VCard,
    Loader,
    Pagination,
    NoResults,
    VCardHeader,
    VCardTitle,
    PageSectionHeader,
  },

  props: ["paymentId"],

  data() {
    return {
      payment: {},
      rows: [],
      meta: {},
      links: {},
      loading: true,
    };
  },

  computed: {
    totalRows() {
      return this.rows.length;
    },

    paymentAmount() {
      return this.payment.amount ? priceFormatter(this.payment.amount) : "N/A";
    },
  },

  methods: {
    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    show() {
      this.loading = true;
      paymentsApi
        .show(this.paymentId)
        .then((response) => {
          this.payment = response.data;
          this.loading = false;
          this.index();
        })
        .catch((error) => console.log(error));
    },

    updateVendorPayment(vendorPayment) {
      this.rows.forEach((row) => {
        if (
          row.id === vendorPayment.id &&
          row.status.id !== vendorPayment.status.id
        ) {
          this.index();
        }
      });
    },

    index(page = null) {
      this.loading = true;
      paymentsApi
        .vendorPaymentsIndex(this.paymentId, page && { page })
        .then((data) => {
          this.rows = data.data;
          this.meta = data.meta;
          this.links = data.links;
          this.scrollTop();
          this.loading = false;
        })
        .catch((error) => console.log(error));
    },
  },

  mounted() {
    this.show();
  },
};
</script>

<style></style>
