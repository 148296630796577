<template>
  <v-table>
    <v-thead>
      <tr>
        <v-table-th>#</v-table-th>
        <v-table-th>Data</v-table-th>
        <v-table-th>Vanzator</v-table-th>

        <v-table-th>Comanda vanzator</v-table-th>
        <v-table-th>Suma</v-table-th>
        <v-table-th>Status</v-table-th>
        <v-table-th>Actiuni</v-table-th>
      </tr>
    </v-thead>
    <v-tbody>
      <tr v-for="row in rows" :key="row.id">
        <v-table-td>
          {{ row.id }}
        </v-table-td>
        <v-table-td>
          {{ formatDate(row.created_at) }}
        </v-table-td>
        <v-table-td>
          <router-link
            :to="{
              name: 'vendors.show',
              params: { id: row.vendor?.id },
            }"
            class="text-yellow-600 hover:text-yellow-900"
          >
            {{ row.vendor?.name }}
          </router-link>
        </v-table-td>
        <v-table-td>
          <router-link
            :to="{
              name: 'vendorOrders.show',
              params: { id: row.vendor_order.id },
            }"
            class="text-yellow-600 hover:text-yellow-900"
          >
            # {{ row.vendor_order.id }}
          </router-link>
        </v-table-td>
        <v-table-td>
          {{ formatPrice(row.amount) }}
        </v-table-td>
        <v-table-td>
          {{ row.status.name }}
        </v-table-td>
        <v-table-td>
          <modal-update-vendor-payment-status
            :vendor-payment-id="row.id"
            @update:vendorPayment="$emit('update:status', $event)"
          />
        </v-table-td>
      </tr>
    </v-tbody>
  </v-table>
</template>

<script>
import VTable from "./ui/VTable.vue";
import VTableTd from "./ui/VTableTd.vue";
import VTableTh from "./ui/VTableTh.vue";
import VTbody from "./ui/VTbody.vue";
import VThead from "./ui/VThead.vue";
import { priceFormatter } from "@/helpers";
import ModalUpdateVendorPaymentStatus from "@/components/ModalUpdateVendorPaymentStatus.vue";

export default {
  components: {
    VTableTd,
    VTable,
    VThead,
    VTableTh,
    VTbody,
    ModalUpdateVendorPaymentStatus,
  },

  props: {
    rows: {
      type: Array,
    },
  },

  emits: ["update:status"],

  methods: {
    // updateVendorPaymentStatus(vendorPayment) {
    //   this.$emit("update:status", vendorPayment);
    // },

    formatPrice(price) {
      return priceFormatter(price);
    },

    formatDate(date) {
      return new Date(date).toLocaleString();
    },
  },
};
</script>
