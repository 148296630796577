import { VENDOR_PAYMENTS, VENDOR_PAYMENTS_SHOW, VENDOR_PAYMENTS_TRANSITIONS } from "../endpoints";
import api from "../api";

export default {
    index(query) {
        let url = new URL(VENDOR_PAYMENTS);

        if (query)
            url.search = new URLSearchParams(query).toString();

        return api.get(url);
    },

    show(vendorPayment = null) {
        if (vendorPayment === null) return;

        const url = VENDOR_PAYMENTS_SHOW.replace(":vendorPayment", vendorPayment);

        return api.get(url);
    },

    update(vendorPayment, data) {
        if (vendorPayment === null) return;

        const url = VENDOR_PAYMENTS_SHOW.replace(":vendorPayment", vendorPayment);

        return api.put(url, data);
    },

    transitionsIndex(vendorPayment, query) {
        if (vendorPayment === null) return;

        let url = new URL(VENDOR_PAYMENTS_TRANSITIONS.replace(":vendorPayment", vendorPayment));

        if (query !== null) {
            url.search = new URLSearchParams(query).toString();;
        }

        return api.get(url);
    },
}